

<template>
  <div class="container-fluid m-2">
    {{ modalData }}

    <div class="row d-flex justify-content-start mb-3 d-flex justify-content-center">
      <button class="btn btn-sm btn-outline-primary mr-2 mb-1" @click="sortByDayChange">
        Sort by daily changes
        <i v-if="sortDescending" class="fas fa-caret-up"></i>
        <i v-else class="fas fa-caret-down"></i>
      </button>
      <button class="btn btn-sm btn-outline-secondary mr-2 mb-1" @click="sortBySymbol">
        Sort by symbol
        <i v-if="sortDescending" class="fas fa-caret-up"></i>
        <i v-else class="fas fa-caret-down"></i>
      </button>
      <button class="btn btn-sm btn-outline-secondary mr-2 mb-1" @click="sortByDate">
        Sort by date
        <i v-if="sortDescending" class="fas fa-caret-up"></i>
        <i v-else class="fas fa-caret-down"></i>
      </button>
      <button class="btn btn-sm btn-outline-danger mr-2 mb-1" @click="resetSorting">Reset sorting</button>
      <button class="btn btn-sm btn-outline-primary mb-1" @click="fetchPrices">Refresh</button>
    </div>
    <div class="row d-flex justify-content-center">
      <div v-for="(crypto, index) in sortedCryptos" :key="index" class="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-3 crypto-cell p-1 shadow rounded text-white">
        <div class="font-weight-bold">{{ crypto.symbol }}</div>
        <div class="font-weight-bold">{{ formatPrice(crypto.price) }} $</div>
        <div :style="{ color: getColorByChange(crypto.dayChange) }">
          {{ typeof crypto.dayChange === 'number' ? crypto.dayChange.toFixed(2) : 'N/A' }}% (Daily)
        </div>
        <div :style="{ color: getColorByChange(crypto.lastChange) }">
          {{ typeof crypto.lastChange === 'number' ? crypto.lastChange.toFixed(2) : 'N/A' }}% (Last)
        </div>
        <div class="font-weight-bold">
          {{ calculateSpeedPerMinute(crypto.dayChange).toFixed(6) }}% (per min)
        </div>
        <!-- <div><button class="btn btn-sm btn-outline-primary" @click="getPredict(crypto.symbol)">Prognoza</button></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'crypto-monitors',
  data() {
    return {
      cryptos: [],
      sortedCryptos: [],
      sortDescending: false,
      currentSort: 'dayChange', // domyślne sortowanie
      period: 5000,
      modalData: null,
      urlServer:'http://146.59.3.21:3000',
      urlWss:'wss://stream.binance.com:9443/ws/!ticker@arr'
    };
  },
  methods: {
    formatPrice(value) {
      return value ? parseFloat(value).toFixed(7) : '0.0000000';
    },

    getColorByChange(change) {
      if (change >= 100) return 'deeppink';
      if (change >= 75) return 'hotpink';
      if (change >= 50) return 'purple';
      if (change >= 25) return 'blue';
      return change > 0 ? 'green' : 'red';
    },

    calculateSpeedPerMinute(dayChange) {
      if (typeof dayChange === 'number') {
        const changePerHour = dayChange / 24; // Dzienna zmiana podzielona przez 24 godziny
        const changePerMinute = changePerHour / 60; // Zmiana na godzinę podzielona przez 60 minut
        return changePerMinute;
      } else {
        return 0;
      }
    },

    async fetchPrices() {
      try {
        const response = await axios.get(this.urlServer+'/api/last-prices');
        this.cryptos = response.data.map(crypto => ({
          symbol: crypto.symbol,
          price: crypto.price,
          lastChange: typeof crypto.changed === 'number' ? crypto.changed : 0,
          dayChange: typeof crypto.changed_day === 'number' ? crypto.changed_day : 0,
          created_at: crypto.created_at
        }));
        this.refreshSortedCryptos();
      } catch (error) {
        console.error('Błąd podczas pobierania cen:', error);
      }
    },

    fetchPricesPeriodically() {
      this.fetchPrices();
      this.interval = setInterval(() => {
        this.fetchPrices();
      }, this.period); // Odświeżaj co 15 sekund
    },

    initWebSocket() {
      const ws = new WebSocket(this.urlWss);
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        this.updateCryptos(data);
      };
    },

    updateCryptos(data) {
      let shouldUpdatePrices = false;
      data.forEach(item => {
        const symbol = item.s;
        const price = parseFloat(item.c);

        const cryptoIndex = this.cryptos.findIndex(c => c.symbol === symbol);
        if (cryptoIndex !== -1) {
          if (this.cryptos[cryptoIndex].price !== price) {
            this.cryptos[cryptoIndex].price = price;
            shouldUpdatePrices = true;
          }
        }
      });

      if (shouldUpdatePrices) {
        this.refreshSortedCryptos(); // Odświeżanie posortowanej listy
      }
    },

    async getPredict(symbol) {
      try {
        const response = await fetch(`http://127.0.0.1:5000/prediction/${symbol}`);
        const data = await response.json();
        this.modalData = data
      } catch (error) {
        console.error("Błąd podczas pobierania prognozy:", error);
      }
    },

  
    refreshSortedCryptos() {
      this.sortedCryptos = [...this.cryptos];
      this.applySorting(true);
    },

    sortByDayChange() {
      if (this.currentSort !== 'dayChange') {
        this.sortDescending = true; // Resetuj kierunek sortowania przy zmianie kryterium
      } else {
        this.sortDescending = !this.sortDescending; // Przełącz kierunek sortowania
      }
      this.currentSort = 'dayChange';
      this.applySorting();
    },

    sortBySymbol() {
      if (this.currentSort !== 'symbol') {
        this.sortDescending = true; // Resetuj kierunek sortowania przy zmianie kryterium
      } else {
        this.sortDescending = !this.sortDescending; // Przełącz kierunek sortowania
      }
      this.currentSort = 'symbol';
      this.applySorting();
    },

    sortByDate() {
      if (this.currentSort !== 'date') {
        this.sortDescending = true; // Resetuj kierunek sortowania przy zmianie kryterium
      } else {
        this.sortDescending = !this.sortDescending; // Przełącz kierunek sortowania
      }
      this.currentSort = 'date';
      this.applySorting();
    },

    applySorting() {
      if (this.currentSort === 'dayChange') {
        this.sortedCryptos.sort((a, b) => this.sortDescending ? b.dayChange - a.dayChange : a.dayChange - b.dayChange);
      } else if (this.currentSort === 'symbol') {
        this.sortedCryptos.sort((a, b) => this.sortDescending ? a.symbol.localeCompare(b.symbol) : b.symbol.localeCompare(a.symbol));
      } else if (this.currentSort === 'date') {
        this.sortedCryptos.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return this.sortDescending ? dateB - dateA : dateA - dateB;
        });
      }
    },

    resetSorting() {
      this.sortedCryptos = [...this.cryptos];
      this.currentSort = ''; // Resetuj obecne sortowanie
    },
  },

  created() {
    this.fetchPrices().then(() => {
      this.initWebSocket(); // Inicjalizacja WebSocket
      this.sortByDayChange(); // Domyślne sortowanie
    });
  },

  mounted() {
    this.fetchPricesPeriodically(); // Uruchom automatyczne odświeżanie po zamontowaniu
    this.renderChart(this.data, this.options)
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval); // Wyczyść interwał, gdy komponent jest niszczony
    }
  },
};
</script>

<style>
  .crypto-cell {
    border: 1px solid #ddd;
    margin: 5px;
    font-size: 0.7rem;
  }
</style>
