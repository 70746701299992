<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark background">
      <div class="container-fluid d-flex justify-content-center">
        <a class="navbar-brand" href="#">
          <img src="/images/resized_logo_for_navbar.png" alt="Logo" height="40">
        </a>
      </div>
    </nav>
    <div class="sunset-line"></div>
    <Monitor />
  </div>
</template>

<script>
import Monitor from './components/Monitor.vue'

export default {
  name: 'App',
  components: {
    Monitor
  }
}
</script>

<style>
  .background {
    background-color: #181A20;
  }

  .navbar {
    display: flex;
    align-items: center;
  }

  .sunset-line {
    height: 5px; /* Dostosuj wysokość linii według potrzeb */
    background: linear-gradient(to right, 
                transparent, 
                rgba(255, 20, 147, 0.5) 45%, 
                rgba(255, 20, 147, 0.8) 50%, 
                rgba(255, 20, 147, 0.5) 55%, 
                transparent);
    margin-top: -5px; /* Przesunięcie w górę, aby linia była bezpośrednio pod navbar */
  }
</style>



